import React from 'react'
import css from './Find.module.css'

const Card = ({image, make}) => {
  return (
    <div className={css.card}>
      <img src={image} alt="" />
      <p>{make}</p>
    </div>
  )
}

export default Card
