import React from 'react'
import css from './Find.module.css'
import {Navigation, Pagination, Scrollbar} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation';
import Card from './Card'
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Find = () => {
  return (
    <div className={css.find}>
     <div className={css.heading}>
        <h1>find your drive</h1>
     
     <div className={css.text_bg}>
        <p>
            <span>Explore the world's largest car sharing marketplace</span>
        </p>
        </div>
     </div>

     <div className={css.slider}>
        <Swiper
        navigation={[Navigation, Pagination, Scrollbar]}
        spaceBetween={10}
        slidesPerView={5}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        breakpoints={{
            340: {
                width: 200,
                slidesPerView: 1,
            },
            768: {
                width: 768,
                slidesPerView: 4,
            },
            1040: {
                width: 1040,
                slidesPerView: 5,
            },
        }}
        pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        >
            <SwiperSlide>
                <Card 
                image='https://images.unsplash.com/photo-1603584173870-7f23fdae1b7a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80'
                make='Audi'
                />
            </SwiperSlide>
            <SwiperSlide>
                <Card 
                image='https://images.unsplash.com/photo-1580414057403-c5f451f30e1c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
                make='Lamborghini'
                />
            </SwiperSlide>
            <SwiperSlide>
                <Card 
                image='https://images.unsplash.com/photo-1596549296654-12f232b3cd1f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=675&q=80'
                make='Ferarri'
                />
            </SwiperSlide>
            <SwiperSlide>
                <Card 
                image='https://images.unsplash.com/photo-1584345604476-8ec5e12e42dd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
                make='Mustang'
                />
            </SwiperSlide>
            <SwiperSlide>
                <Card 
                image='https://images.unsplash.com/photo-1629020607902-3a93e9f8c4b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=753&q=80'
                make='Mercedez'
                />
            </SwiperSlide>
            <SwiperSlide>
                <Card 
                image='https://images.unsplash.com/photo-1571037697753-e796f324bbd6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1155&q=80'
                make='Hyundai'
                />
            </SwiperSlide>
            <SwiperSlide>
                <Card 
                image='https://images.unsplash.com/photo-1607853554439-0069ec0f29b6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80'
                make='BMW'
                />
            </SwiperSlide>
            <SwiperSlide>
                <Card 
                image='https://images.unsplash.com/photo-1560195919-db01654dd115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
                make='Rolls Royce'
                />
            </SwiperSlide>
            <SwiperSlide>
                <Card 
                image='https://images.unsplash.com/photo-1612814251241-6089fc8fee42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80'
                make='Dodge Charger'
                />
            </SwiperSlide>
            <SwiperSlide>
                <Card 
                image='https://images.unsplash.com/photo-1616453902318-70334c1b1c8a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=674&q=80'
                make='McLaren'
                />
            </SwiperSlide>
        </Swiper>
     </div>
    </div>
  )
}

export default Find
