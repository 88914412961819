import React from 'react'
import css from './Driver.module.css'
import Drive from '../../images/drive.png'

const Driver = () => {
  return (
    <div className={css.driver}>
        <div className={css.left}>
            <img src={Drive} alt="" />
        </div>
        <div>
            <h2>Find the perfect car <span className='blue'>to try before you buy</span></h2>
            <p>Make sure your future wheels work well with your lifestyle by taking your time in the driver's seat.</p>
            <button className={css.btn}>Browse Cars</button>
        </div>
    </div>
  )
}

export default Driver
