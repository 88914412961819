import React from 'react'
import css from './Footer.module.css'
import logos from '../../images/logo_dark.png'

const Footer = () => {
  return (
    <div className={css.footer}>
        <img src={logos} alt="" />
        <button>Select a Car</button>
    </div>
  )
}

export default Footer