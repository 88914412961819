import React, { useState } from 'react'
import css from './Navbar.module.css'
import {AiOutlineMenu, AiOutlineClose, AiOutlineSearch, AiOutlineUser} from 'react-icons/ai'
import Logo from '../../images/logo.png'

const Navbar = () => {

    const [nav, setNav] = useState(false)
  return (
    <header className={css.navbar}>
      <img src={Logo} alt="/" />
      <nav>
      <ul className={nav ? [css.links, css.active].join(' ') : [css.links]}>
        <li><a href="/">Learn More</a></li>
        <li><a href="/">Log in</a></li>
        <li><a href="/">Sign up</a></li>
        <li><AiOutlineSearch size={25} style={{marginTop: '7px', cursor: 'pointer'}}/></li>
        <li><AiOutlineUser size={25} style={{marginTop: '7px', cursor: 'pointer'}}/></li>
      </ul>
      </nav>

      <div onClick={() => setNav(!nav)} className={css.mobile_btn}>
        {nav ? <AiOutlineClose size={25}/> : <AiOutlineMenu size={25}/> }
        
        
      </div>
    </header>
  )
}

export default Navbar
