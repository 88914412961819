import React from 'react'
import css from './Hero.module.css'
import {AiOutlineSearch} from 'react-icons/ai'

const Hero = () => {
  return (
    <div className={css.hero}>
      <form className={css.form}>
        <div className={css.text}>
            <label>Where</label>
            <input className={css.text_input} type="text" placeholder='Search Location'/>
        </div>
        <div className={css.from}>
            <span className={css.border}></span>
            <label>From</label>
            <input type="date" />
        </div>
        <div className={css.until}>
        <span className={css.border}></span>
            <label>Until</label>
            <input type="date" />
        </div>
        <div className={css.search}>
            <AiOutlineSearch className={css.icon}/>
            <button className={css.btn}>Search for cars</button>
        </div>
      </form>
    </div>
  )
}

export default Hero
